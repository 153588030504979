(function(window, document) {
  "use strict";

  function updateBotonMercadopago(e) {
    if (e.classList && e.classList.contains('mercadopago-button')) {
      e.classList.add('btn');
      e.classList.add('btn-primary');
      e.classList.add('mt-4');
      e.classList.add('rounded-pill');
      e.classList.remove('mercadopago-button');
      e.innerText = 'Comprar';
      const wmp = document.getElementById("waiting-mercadopago");
      if (wmp) {
        wmp.classList.add('hidden');
      }
    }
  }

  function monitorearBotonMercadopago(containerId)
  {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById(containerId);

    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };

    // Callback function to execute when mutations are observed
    const callback = function(mutationsList, observer) {
        for(const mutation of mutationsList) {
            if (mutation.type === 'childList') {
              for(const e of mutation.addedNodes) {
                updateBotonMercadopago(e); 
              }
            }
        }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);

    // Later, you can stop observing
    // observer.disconnect();
  }

  window.addEventListener('load', () => {
    // lo mismo en los existentes
    let btnList = [];
    for(const e of document.getElementById('pricing').getElementsByClassName('mercadopago-button')) {
      btnList.push(e);
    }
    for(const e of btnList) {
      updateBotonMercadopago(e); 
    }
    // ajustar el estilo del boton de Mercadopago, cuando se creen
    monitorearBotonMercadopago('pricing');
  });

})(window, document);

